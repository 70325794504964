import React from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";

let AzureAppInsights: React.ElementType<any> = (props: { children: any }) => {
  return <>{props.children}</>;
};

if (process.env.APPLICATIONINSIGHTS_CONNECTION_STRING) {
  const defaultBrowserHistory = {
    url: "/",
    location: {
      pathname: "",
    },
    listen: () => {},
  } as any;

  let browserHistory = defaultBrowserHistory;

  if (typeof window !== "undefined") {
    browserHistory = { ...browserHistory, ...window.history };
    browserHistory.location.pathname = (browserHistory as any)?.state?.url;
  }

  const reactPlugin = new ReactPlugin();

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.APPLICATIONINSIGHTS_CONNECTION_STRING,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableAjaxPerfTracking: true,
      isBrowserLinkTrackingEnabled: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });

  if (typeof window !== "undefined") {
    appInsights.loadAppInsights();
  }

  const AzureAppInsightsComponent = (props: { children: any }) => {
    const { children } = props;
    return (
      <AppInsightsErrorBoundary
        onError={() => <h1>Something is wrong. Please contact developer@legacyhc.org of this issue!</h1>}
        appInsights={reactPlugin}
      >
        <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
      </AppInsightsErrorBoundary>
    );
  };

  AzureAppInsights = AzureAppInsightsComponent;
}

export { AzureAppInsights };
