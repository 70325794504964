import "~/styles/globals.css";

import { AppProps } from "next/app";
import Head from "next/head";
import { trpc } from "~/trpc";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AzureAppInsights } from "~/monitoring/withAITracking";
import { useRouter } from "next/router";

function App(props: AppProps) {
  const { Component, pageProps } = props;

  const router = useRouter();
  const devtools = router.query.devtools;

  return (
    <>
      <Head>
        <title>LEAPS</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, user-scalable=no"
        />
      </Head>

      {typeof devtools !== "undefined" && (
        <ReactQueryDevtools
          initialIsOpen={false}
          panelPosition={"right"}
          position={"bottom-right"}
        />
      )}

      <AzureAppInsights>
        <Component {...pageProps} />
      </AzureAppInsights>
    </>
  );
}

export default trpc.withTRPC(App);
